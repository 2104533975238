/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.PartsCatalogueItemFilter = (function() {

    /**
     * Namespace PartsCatalogueItemFilter.
     * @exports PartsCatalogueItemFilter
     * @namespace
     */
    var PartsCatalogueItemFilter = {};

    PartsCatalogueItemFilter.PartsCatalogueItemFilterModel = (function() {

        /**
         * Properties of a PartsCatalogueItemFilterModel.
         * @memberof PartsCatalogueItemFilter
         * @interface IPartsCatalogueItemFilterModel
         * @property {string|null} [BodyType] PartsCatalogueItemFilterModel BodyType
         * @property {string|null} [BodyID] PartsCatalogueItemFilterModel BodyID
         * @property {string|null} [sortColumn] PartsCatalogueItemFilterModel sortColumn
         * @property {string|null} [sortOrder] PartsCatalogueItemFilterModel sortOrder
         * @property {number|null} [pageIndex] PartsCatalogueItemFilterModel pageIndex
         * @property {number|null} [pageSize] PartsCatalogueItemFilterModel pageSize
         */

        /**
         * Constructs a new PartsCatalogueItemFilterModel.
         * @memberof PartsCatalogueItemFilter
         * @classdesc Represents a PartsCatalogueItemFilterModel.
         * @implements IPartsCatalogueItemFilterModel
         * @constructor
         * @param {PartsCatalogueItemFilter.IPartsCatalogueItemFilterModel=} [p] Properties to set
         */
        function PartsCatalogueItemFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * PartsCatalogueItemFilterModel BodyType.
         * @member {string|null|undefined} BodyType
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @instance
         */
        PartsCatalogueItemFilterModel.prototype.BodyType = null;

        /**
         * PartsCatalogueItemFilterModel BodyID.
         * @member {string|null|undefined} BodyID
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @instance
         */
        PartsCatalogueItemFilterModel.prototype.BodyID = null;

        /**
         * PartsCatalogueItemFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @instance
         */
        PartsCatalogueItemFilterModel.prototype.sortColumn = null;

        /**
         * PartsCatalogueItemFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @instance
         */
        PartsCatalogueItemFilterModel.prototype.sortOrder = null;

        /**
         * PartsCatalogueItemFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @instance
         */
        PartsCatalogueItemFilterModel.prototype.pageIndex = null;

        /**
         * PartsCatalogueItemFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @instance
         */
        PartsCatalogueItemFilterModel.prototype.pageSize = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * PartsCatalogueItemFilterModel _BodyType.
         * @member {"BodyType"|undefined} _BodyType
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @instance
         */
        Object.defineProperty(PartsCatalogueItemFilterModel.prototype, "_BodyType", {
            get: $util.oneOfGetter($oneOfFields = ["BodyType"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PartsCatalogueItemFilterModel _BodyID.
         * @member {"BodyID"|undefined} _BodyID
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @instance
         */
        Object.defineProperty(PartsCatalogueItemFilterModel.prototype, "_BodyID", {
            get: $util.oneOfGetter($oneOfFields = ["BodyID"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PartsCatalogueItemFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @instance
         */
        Object.defineProperty(PartsCatalogueItemFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PartsCatalogueItemFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @instance
         */
        Object.defineProperty(PartsCatalogueItemFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PartsCatalogueItemFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @instance
         */
        Object.defineProperty(PartsCatalogueItemFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * PartsCatalogueItemFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @instance
         */
        Object.defineProperty(PartsCatalogueItemFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified PartsCatalogueItemFilterModel message. Does not implicitly {@link PartsCatalogueItemFilter.PartsCatalogueItemFilterModel.verify|verify} messages.
         * @function encode
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @static
         * @param {PartsCatalogueItemFilter.IPartsCatalogueItemFilterModel} m PartsCatalogueItemFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PartsCatalogueItemFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.BodyType != null && Object.hasOwnProperty.call(m, "BodyType"))
                w.uint32(10).string(m.BodyType);
            if (m.BodyID != null && Object.hasOwnProperty.call(m, "BodyID"))
                w.uint32(18).string(m.BodyID);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(26).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(34).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(40).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(48).int32(m.pageSize);
            return w;
        };

        /**
         * Encodes the specified PartsCatalogueItemFilterModel message, length delimited. Does not implicitly {@link PartsCatalogueItemFilter.PartsCatalogueItemFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @static
         * @param {PartsCatalogueItemFilter.IPartsCatalogueItemFilterModel} message PartsCatalogueItemFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PartsCatalogueItemFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PartsCatalogueItemFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {PartsCatalogueItemFilter.PartsCatalogueItemFilterModel} PartsCatalogueItemFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PartsCatalogueItemFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.PartsCatalogueItemFilter.PartsCatalogueItemFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.BodyType = r.string();
                    break;
                case 2:
                    m.BodyID = r.string();
                    break;
                case 3:
                    m.sortColumn = r.string();
                    break;
                case 4:
                    m.sortOrder = r.string();
                    break;
                case 5:
                    m.pageIndex = r.int32();
                    break;
                case 6:
                    m.pageSize = r.int32();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a PartsCatalogueItemFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {PartsCatalogueItemFilter.PartsCatalogueItemFilterModel} PartsCatalogueItemFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PartsCatalogueItemFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return PartsCatalogueItemFilterModel;
    })();

    return PartsCatalogueItemFilter;
})();

module.exports = $root;
