<template>
  <div>
    <app-detail-header
      :show-toggle="true"
      :show-new="false"
      :show-delete="false"
      :show-save="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <parts-catalogue-side-menu
          v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-chile box detail-page-tile">
            <bulma-table
              class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr
                v-for="(item, index) in entity"
                :key="item.RowNumber"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>{{ item.itemNo }}</td>
                <td>{{ item.bodyID }}</td>
                <td>{{ item.side }}</td>
                <td class="has-text-right">{{ item.unitPrice | formatCurrency($userInfo.locale) }}</td>
                <td>{{ item.partDesc }}</td>
                <td>{{ item.partNo }}</td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import { AppHeaderButtonTypes } from '@/enums'
import PartsCatalogueSideMenu from './PartsCatalogueSideMenu'
import PartsCatalogueValidation from './PartsCatalogueValidation'
import PartsCatalogueService from './PartsCatalogueService'
import PartsCatalogueRoutes from './route-types'
import _cloneDeep from 'lodash/cloneDeep'
import BulmaTable from '@/components/BulmaTable'
// import Pagination from '@/components/BulmaPagination'
import { ItemColumns } from './columns'
import StoreMixin from './storeMixin'
import _isEmpty from 'lodash/isEmpty'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import _debounce from 'lodash.debounce'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'PartsCatalogueItemListView',
  components: {
    AppDetailHeader,
    [PartsCatalogueSideMenu.name]: PartsCatalogueSideMenu,
    // Pagination,
    BulmaTable
  },
  mixins: [StoreMixin, NumberFiltersMixin, PartsCatalogueValidation],
  props: {
    returnUrl: String
  },
  data() {
    return {
      entity: [],
      totalRows: 0,
      filter: {
        BodyType: 'All',
        BodyID: '0',
        sortColumn: 'ItemNo',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 20
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return ItemColumns
    }
  },
  watch: {
    entity: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 500),
      deep: true
    }
  },
  beforeRouteLeave: async function(to, from, next) {
    const isPrintPreview = to.name === PrintPreviewRoutes.PrintPreview.name
    if (isPrintPreview) {
      next()
    } else {
      this.clearSnapshots(this.entity.item)
      this.clearSessionStorage(this.filterKey1)
      this.clearRouteMeta()
      next()
    }
  },
  beforeDestroy() {},
  async created() {
    this.getEntity()
  },
  mounted() {
    this.$v.filter.$touch()
  },
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    filterList() {
      if (this.$v.filter.$error) {
        this.$notification.openNotificationWithType('danger', 'Parts Catalogue Item', 'Filter validation errors.')
        return false
      }
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getEntity()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntity()
    },
    resetList() {
      this.filter.BodyType = 'All'
      this.filter.BodyID = '0'
      this.filter.sortColumn = 'ItemNo'
      this.filter.sortOrder = ''
      this.filter.pageIndex = 1
      this.filter.pageSize = 20
      this.persistFilter()
      this.getEntity()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey1, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey1))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntity()
    },
    async getEntity() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entity = await PartsCatalogueService.getEntity(this.$route.params.partsCatalogNo, this.$route.params.make, this.$route.params.modelCode, this.filter)
      this.totalRows = this.entity.length > 0 ? this.entity[0].totalRows : 0
      this.isTableLoading = false
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
        console.log(this.snapshotDiff)
        this.isUnsavedModalActive = true
      } else {
        if (this.returnUrl) {
          this.clearSessionStorage()
          this.clearSnapshots(this.entity.partsCatalogNo)
          window.location.href = `${process.env.VUE_APP_ROOT_URI}${this.returnUrl}`
        } else if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else if (this.returnRoute && !_isEmpty(this.returnRoute)) {
          this.$router.push(this.returnRoute.fullPath)
        } else {
          this.$router.push({
            name: PartsCatalogueRoutes.PartsCatalogueListView.name
          })
        }
      }
    },
    persistQueries() {
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|partscatalogue|returnUrl`, this.returnUrl)
      }
      if (this.type) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|partscatalogue|type`, this.type)
      }
    },
    replaceRoute(id) {
      const newMeta = Object.assign(this.$route.meta, {
        returnUrl: this.returnUrl,
        type: this.type
      })
      this.$router.replace({
        name: PartsCatalogueRoutes.PartsCatalogueItemListView.name,
        params: { partsCatalogNo: id },
        meta: newMeta
      })
    },
    clearRouteMeta() {
      // Reset returnUrl meta in case user clicked on other V2
      if (this.$route.meta.returnUrl) {
        this.$route.meta.returnUrl = null
      }
    },
    async print1() {
      const params = {
        CatNo: this.$route.params.partsCatalogNo,
        ModelCode: this.$route.params.modelCode,
        Make: this.$route.params.make,
        bodytype: this.filter.BodyType,
        bodyid: this.filter.BodyID
      }
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Parts Catalogue Item List'
      emailer.reportName = 'rptPartsCatalogls'
      this.addEmailer(emailer)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId:  this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    clearSessionStorage() {
      this.removeQueries()
      this.removeFilters()
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|partscatalogue|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|partscatalogue|type`)
    },
    removeFilters() {
      sessionStorage.removeItem(this.filterKey1)
    }
  }
}
</script>
