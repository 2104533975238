import { TableColumn } from '@/classes'

const ListColumns = [
  new TableColumn('Model ID', 'ModelID', false, '', 'left'),
  new TableColumn('Vehicle Make', 'Make', false, '', 'left'),
  new TableColumn('Vehicle Model', 'Model', false, '', 'left'),
  new TableColumn('Bodies', 'Bodies', false, '', 'left'),
  new TableColumn('Cylinders', 'Cylinders', false, '', 'left'),
  new TableColumn('Month/Year', 'ProductionYear', false, '', 'left'),
  new TableColumn('Catalog No', 'CatalogNo', false, '', 'left'),
  new TableColumn('', 'Browse', false, '', 'centered')
]

const ItemColumns = [
  new TableColumn('Item', 'itemNo', false, '', 'left'),
  new TableColumn('Body', 'bodyID', false, '', 'left'),
  new TableColumn('Side', 'side', false, '', 'left'),
  new TableColumn('Unit Price', 'unitPrice', false, '', 'right'),
  new TableColumn('Part Name', 'partDesc', false, '', 'left'),
  new TableColumn('Part Number', 'partNo', false, '', 'left')
]
export { ListColumns, ItemColumns }
