<template>
  <app-side-menu>
    <aside
      class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">Filters</span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input
                class="input is-capitalized"
                type="text"
                placeholder="Make"
                v-model="innerValue.Make"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button
            class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button
            class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>

    </aside>
  </app-side-menu>
</template>
<script>
import AppSideMenu from '@/components/AppSideMenu'
import _cloneDeep from 'lodash/cloneDeep'
import { mask } from 'vue-the-mask'

export default {
  name: 'PartsCatalogueListSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu
  },
  directives: {
    mask
  },
  props: {
    filter: null,
    value: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      selectedActive: 1,
      stateList: null
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    value: {
      handler: function(newVal, oldVal) {
        this.innerValue = this.value
      },
      deep: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    onFilterClick() {
      this.$emit('filter')
    },
    onResetClick() {
      this.$emit('reset')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';
input.uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
