<template>
  <div v-if="$userInfo">
    <app-detail-header
      :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <parts-catalogue-list-side-menu
          v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table
              class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr
                v-for="(entity, index) in entitySummaries"
                :key="entity.modelCode"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>{{ entity.modelCode }}</td>
                <td>{{ entity.make }}</td>
                <td>{{ entity.model }}</td>
                <td>{{ entity.bodies }}</td>
                <td>{{ entity.cylinders }}</td>
                <td>{{ entity.productionYear }}</td>
                <td>{{ entity.partsCatalogNo }}</td>
                <td class="has-text-centered">
                  <router-link
                    class="button is-primary is-small is-inverted"
                    :to="'/partscatalogues/' + entity.partsCatalogNo + '/' + entity.make + '/' + entity.modelCode">
                    <span class="icon is-medium">
                      <i class="mdi mdi-format-list-bulleted mdi-24px" />
                    </span>
                  </router-link>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import PartsCatalogueListSideMenu from './PartsCatalogueListSideMenu'
import PartsCatalogueService from './PartsCatalogueService'
import { AppHeaderButtonTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import PartsCatalogueValidation from './PartsCatalogueValidation'
import { ListColumns } from './columns'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import StoreMixin from './storeMixin'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'PartscatalogueListView',
  components: {
    AppDetailHeader,
    [PartsCatalogueListSideMenu.name]: PartsCatalogueListSideMenu,
    BulmaTable
  },
  mixins: [StoreMixin, PartsCatalogueValidation],
  data() {
    return {
      entitySummaries: [],
      totalRows: 0,
      filter: {
        Make: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 20
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return ListColumns
    }
  },
  created() {
    this.getEntitySummaries()
  },
  mounted() {
    this.$v.filter.$touch()
  },
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    filterList() {
      if (this.$v.filter.$error) {
        this.$notification.openNotificationWithType('danger', 'Parts Catalogue Information', 'Filter validation errors.')
        return false
      }
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getEntitySummaries()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    resetList() {
      this.filter.Make = ''
      this.filter.sortColumn = ''
      this.filter.sortOrder = ''
      this.filter.pageIndex = 1
      this.filter.pageSize = 20
      this.persistFilter()
      this.getEntitySummaries()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntitySummaries()
    },
    async getEntitySummaries() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await PartsCatalogueService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    print1() {
      const params = {
        CompanyID: this.$userInfo.companyId,
        FilterByMake: this.filter.Make,
        SortBy: this.filter.sortOrder
      }
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Parts Catalogue List'
      emailer.reportName = 'PartsCatalogueList'
      this.addEmailer(emailer)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    }
  }
}
</script>
