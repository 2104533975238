import FqApi from '@/services/fq-api'
//import ProtoBufApi from '@/services/protobuf-api'
import ProtoBuf from 'protobufjs'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialisePartsCatalogueFilter(filter)
    const url = `partscatalogues/list?filter=${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getEntity(id, make, modelCode, filter) {
    const serialised = this.serialisePartsCatalogueItemFilter(filter)
    const url = `/partscatalogues?CatalogNo=${id}&Make=${make}&ModelCode=${modelCode}&filter=${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  serialisePartsCatalogueFilter(filter) {
    const entityProto = require('../../assets/proto/partscatalogue/PartsCatalogueFilter.proto')
    const filterMessage = entityProto.PartsCatalogueFilter.PartsCatalogueFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  serialisePartsCatalogueItemFilter(filter) {
    const entityProto = require('../../assets/proto/partscatalogue/PartsCatalogueItemFilter.proto')
    const filterMessage = entityProto.PartsCatalogueItemFilter.PartsCatalogueItemFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  }
}
